import React, { useState, useRef } from "react";
import { Card, Badge } from "react-bootstrap";
import "./bestseller.css";

const products = [
  {
    id: 1,
    name: "Peda",
    price: "₹600/Kg",
    image: "images/peda.png",
    description: "Peda is a popular Indian sweet made from condensed milk and flavored with cardamom or saffron. It is often garnished with nuts and has a rich, creamy texture. Peda is commonly enjoyed as a festive treat.",
    tags: ["Chocolate", "Cakes"],
    bestSeller: true,
  },
  {
    id: 2,
    name: "Barfi",
    price: "₹700/Kg",
    image: "images/barfi.png",
    description: "Barfi is a popular Indian sweet made from condensed milk and various flavorings such as cardamom, nuts, and fruit. It is known for its smooth, creamy texture and can be garnished with edible silver leaf.",
    tags: ["Traditional", "Desserts"],
    bestSeller: false,
  },
  {
    id: 3,
    name: "Gulab Jamun",
    price: "₹20/piece",
    image: "images/gulabjamun.png",
    description: "Gulab Jamun is a popular Indian dessert made from milk solids, sugar, and ghee. The dough balls are deep-fried and then soaked in a fragrant sugar syrup. They are known for their rich, sweet flavor and soft texture.",
    tags: ["Popular", "Sweet"],
    bestSeller: true,
  },
  {
    id: 4,
    name: "Kalakand",
    price: "₹700/Kg",
    image: "images/kalakand.png",
    description: "Kalakand is a popular Indian sweet made from condensed milk and sugar. It has a grainy texture and is often flavored with cardamom and garnished with nuts. It is enjoyed during festivals and special occasions.",
    tags: ["Popular", "Sweet"],
    bestSeller: true,
  },
];

const BestSeller = () => {
  const [expandedProductId, setExpandedProductId] = useState(null);
  const containerRef = useRef(null);

  const scroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = 300;
      containerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleReadMore = (id) => {
    setExpandedProductId(expandedProductId === id ? null : id);
  };

  return (
    <div className="best-seller-section">
      <h2 className="section-title">Best Sellers</h2>
      <div className="product-container" ref={containerRef}>
        {products.map((product) => (
          <div className="product-wrapper" key={product.id}>
            {product.bestSeller && <div className="best-seller-tag">Best Seller</div>}
            <Card className="product-card">
              <Card.Img variant="top" src={product.image} alt={product.name} />
              <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                <div className={`product-description ${expandedProductId === product.id ? 'expanded' : ''}`}>
                  <Card.Text>{product.description}</Card.Text>
                </div>
                {product.description.length > 60 && (
                  <span
                    className="read-more-tag"
                    onClick={() => handleReadMore(product.id)}
                  >
                    {expandedProductId === product.id ? 'Read Less' : 'Read More'}
                  </span>
                )}
                <div className="product-tags">
                  {product.tags.map((tag, index) => (
                    <Badge key={index} pill bg="warning" className="tag-badge">
                      {tag}
                    </Badge>
                  ))}
                  {/* Add price as a tag with consistent styling */}
                  <Badge pill bg="info" className="tag-badge price-badge">
                    {product.price}
                  </Badge>
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
      <div className="scroll-buttons">
        <button className="scroll-button left" onClick={() => scroll("left")}>
          &#10094;
        </button>
        <button className="scroll-button right" onClick={() => scroll("right")}>
          &#10095;
        </button>
      </div>
    </div>
  );
};

export default BestSeller;
