import { Col, Row } from "react-bootstrap";
import {
  FaPhone,
  FaWhatsapp,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import "./About.css";

const About = () => {
  const handlePhoneClick = () => {
    window.location.href = "tel:+918299721854"; // Replace with your phone number
  };
  const handleEmailClick = () => {
    window.location.href = "mailto:customercare@laxmisweets.in?subject=Inquiry&body=Hello, I would like to inquire about..."; 
  };
  return (
    <div className="about-container">
      {/* Section 1: The Story */}
      <Row className="about-row story-section">
        <Col xs={12} md={6} className="about-image-col">
          <img
            src="/images/story.png"
            alt="The Story"
            className="about-image"
          />
        </Col>
        <Col xs={12} md={6} className="about-col">
          <h2 className="about-title">The Story / कहानी</h2>
          <p className="about-text">
            Laxmi Sweet Shop is a new celebratory venture by Laxmi Sweets., the
            company behind The Laxmi Sweets Canteen. The timely
            launch marks the 54th anniversary of Laxmi Sweets. and fittingly
            puts the spotlight on the sweets that shaped all our happy
            occasions. However, it’s not mithai as we know it.
            <br />
            लक्ष्मी स्वीट शॉप, लक्ष्मी स्वीट्स द्वारा एक नया उत्सव है, जो द
            लक्ष्मी स्वीट्स कैंटीन और ओ पेड्रो के पीछे की कंपनी है। इस समय पर
            शुरू हुआ उद्यम, लक्ष्मी स्वीट्स की 54वीं वर्षगांठ को चिह्नित करता है
            और उन मिठाइयों पर प्रकाश डालता है जिन्होंने हमारे सभी खुशहाल अवसरों
            को आकार दिया। हालांकि, यह वैसी मिठाई नहीं है जैसी हम जानते हैं।
          </p>
          <p className="about-text">
            Staying true to their ethos of ‘Re-creation’, the thought that has
            driven The Laxmi Sweets Canteen and also shaped, the company
            looked at Indian sweets and the tradition of gifting sweets through
            a new lens of play. The team scoured India, gathering knowledge,
            recipes, and imbibing techniques from the masters.
            <br />
            ‘पुनर्निर्माण’ के अपने सिद्धांत के प्रति सच्चे रहते हुए, जिसने द
            लक्ष्मी स्वीट्स कैंटीन को प्रेरित किया और ओ पेड्रो को भी आकार दिया,
            कंपनी ने भारतीय मिठाइयों और मिठाई उपहार देने की परंपरा को एक नए
            दृष्टिकोण से देखा। टीम ने भारत भर में जानकारी एकत्र की, व्यंजनों को
            इकट्ठा किया, और मास्टरों से तकनीकों को सीखा।
          </p>
        </Col>
      </Row>
      {/* Section 2: Our Philosophy */}
      <Row className="about-row philosophy-section">
        <Col xs={12} md={6} className="order-md-2 about-image-col">
          <img
            src="/images/philosophy.png"
            alt="Our Philosophy"
            className="about-image"
          />
        </Col>
        <Col xs={12} md={6} className="about-col">
          <h2 className="about-title">Our Philosophy / हमारी फिलॉसफी</h2>
          <p className="about-text">
            At Laxmi Sweets Sweet Shop, we believe in celebrating the rich
            traditions of Indian sweets while adding our own creative twist. Our
            philosophy revolves around the joy of discovery, where each sweet
            brings a unique experience of flavors and presentation.
            <br />
            लक्ष्मी स्वीट्स शॉप में, हम भारतीय मिठाइयों की समृद्ध परंपराओं का
            जश्न मनाते हैं और अपनी रचनात्मकता का तड़का जोड़ते हैं। हमारी फिलॉसफी
            खोज की खुशी के इर्द-गिर्द घूमती है, जहां प्रत्येक मिठाई स्वाद और
            प्रस्तुति का एक अनूठा अनुभव लाती है।
          </p>
          <p className="about-text">
            Our chefs meticulously craft each sweet, blending traditional
            recipes with modern techniques to surprise and delight your taste
            buds. From the classic laddoos to innovative creations, every bite
            tells a story of passion, creativity, and love for sweets.
            <br />
            हमारे शेफ प्रत्येक मिठाई को पारंपरिक व्यंजनों और आधुनिक तकनीकों का
            मिश्रण करके तैयार करते हैं ताकि आपके स्वाद का विस्मय और आनंद बढ़ाया
            जा सके। क्लासिक लड्डू से लेकर अभिनव रचनाओं तक, हर बाइट मिठाइयों के
            प्रति जुनून, रचनात्मकता और प्यार की कहानी बयां करती है।
          </p>
        </Col>
      </Row>
      {/* Section 3: Celebrating the Diversity of Indian Desserts */}
      <Row className="about-row diversity-section">
        <Col xs={12} md={6} className="about-image-col">
          <img
            src="/images/diversity.png"
            alt="Celebrating the Diversity of Indian Desserts"
            className="about-image"
          />
        </Col>
        <Col xs={12} md={6} className="about-col">
          <h2 className="about-title">
            Celebrating the Diversity of Indian Desserts / भारतीय मिठाइयों की
            विविधता का जश्न
          </h2>
          <p className="about-text">
            India is a land of diverse cultures, and each region boasts its own
            unique sweets and desserts. At Laxmi Sweets Sweet Shop, we celebrate
            this diversity by offering a wide range of traditional and modern
            sweets, each crafted with authentic ingredients and techniques.
            <br />
            भारत विविध संस्कृतियों की भूमि है, और प्रत्येक क्षेत्र अपनी विशिष्ट
            मिठाइयों और डेसर्ट का दावा करता है। लक्ष्मी स्वीट्स शॉप में, हम इस
            विविधता का जश्न पारंपरिक और आधुनिक मिठाइयों की एक विस्तृत श्रृंखला
            की पेशकश करके मनाते हैं, जिन्हें प्रामाणिक सामग्री और तकनीकों के साथ
            तैयार किया जाता है।
          </p>
          <p className="about-text">
            From the rich, syrupy gulab jamuns of North India to the delicate,
            coconut-infused sweets of South India, our menu is a tribute to the
            country's rich culinary heritage. We strive to honor these
            time-honored recipes while infusing them with our own modern flair.
            <br />
            उत्तर भारत के समृद्ध, सिरप से लथपथ गुलाब जामुन से लेकर दक्षिण भारत
            की नाजुक, नारियल से भरपूर मिठाइयों तक, हमारा मेनू देश की समृद्ध पाक
            विरासत को श्रद्धांजलि है। हम इन समय-सम्मानित व्यंजनों को सम्मानित
            करने का प्रयास करते हैं, जबकि उनमें अपना आधुनिक स्वाद जोड़ते हैं।
          </p>
        </Col>
      </Row>
      {/* Section 4: Crafting a New Gifting Tradition */}
      <Row className="about-row gifting-section">
        <Col xs={12} md={6} className="order-md-2 about-image-col">
          <img
            src="/images/gifting.png"
            alt="Crafting a New Gifting Tradition"
            className="about-image"
          />
        </Col>
        <Col xs={12} md={6} className="about-col">
          <h2 className="about-title">
            Crafting a New Gifting Tradition / उपहार देने की नई परंपरा
          </h2>
          <p className="about-text">
            Gifting sweets is a cherished tradition in Indian culture,
            symbolizing joy, love, and celebration. At Laxmi Sweets Sweet Shop,
            we are reimagining this tradition with our curated collection of
            artisanal sweets, perfect for every occasion.
            <br />
            भारतीय संस्कृति में मिठाई उपहार में देना एक सम्मानित परंपरा है, जो
            खुशी, प्यार और उत्सव का प्रतीक है। लक्ष्मी स्वीट्स में, हम अपने
            क्यूरेटेड संग्रह के साथ इस परंपरा की पुन: कल्पना कर रहे हैं, जो हर
            अवसर के लिए उपयुक्त है।
          </p>
          <p className="about-text">
            Our beautifully packaged sweets make for the perfect gift, whether
            it's for a festive occasion, a special milestone, or just to show
            someone you care. Each sweet box is thoughtfully designed to delight
            the senses and create lasting memories.
            <br />
            हमारे खूबसूरती से पैक किए गए मिठाई उपहार किसी उत्सव के अवसर, किसी
            विशेष मील का पत्थर, या सिर्फ किसी को अपने ख्याल का इजहार करने के लिए
            आदर्श बनाते हैं। प्रत्येक मिठाई बॉक्स इंद्रियों को प्रसन्न करने और
            स्थायी यादें बनाने के लिए सोच-समझकर डिज़ाइन किया गया है।
          </p>
        </Col>
      </Row>
      {/* Section 5: Founders */}
      <Row className="about-row founder-section">
        <Col xs={12} className="about-col">
          <h2 className="about-title">Our Founders / हमारे संस्थापक</h2>
          <Row>
            <Col xs={12} md={4} className="founder-card">
              <img
                src="/images/sushill.png"
                style={{ marginTop: " 85px" }}
                alt="Sushil Kumar"
                className="founder-image"
              />
              <div className="founder-info">
                <h3>Sushil Kumar gupta</h3>
                <p>Having 45+ Year of Experience in Sweet industry.</p>
              </div>
            </Col>
            <Col xs={12} md={4} className="founder-card">
              <img
                src="/images/raghubeer.png"
                alt="Raghubeer gupta"
                className="founder-image"
              />
              <div className="founder-info">
                <h3>Raghubeer Gupta</h3>
                <p>Having 45+ Year of Experience in Sweet industry.</p>
              </div>
            </Col>
            <Col xs={12} md={4} className="founder-card">
              <img
                src="/images/manish.png"
                alt="Founder 3"
                className="founder-image"
              />
              <div className="founder-info">
                <h3>Manish Gupta</h3>
                <p>Having 20+ Year of Experience in Sweet industry.</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Section 6: Contact Us */}
      <Row className="about-row contact-section">
        <Col xs={12} className="about-col">
          <h2 className="about-title">Contact Us / संपर्क करें</h2>
          <Row onClick={handlePhoneClick} className="contact-details">
            <Col xs={12} md={6} className="contact-detail">
              <FaPhone className="contact-icon" /> <span>Phone / फोन:</span>{" "}
              +91-8299721854 / +91-9305110788
            </Col>
            <Col xs={12} md={6} className="contact-detail">
              <FaWhatsapp className="contact-icon" />{" "}
              <span>WhatsApp / व्हाट्सएप:</span>{" "}
              <a
                style={{ color: "inherit", textDecoration: "none" }}
                href="//api.whatsapp.com/send?phone=918299721854&text=Hi I want to place an order."
              >
                +91-8299721854 / +91-9305110788
              </a>
            </Col>
          </Row>
          <Row className="contact-details">
            <Col onClick={handleEmailClick} xs={12} md={6} className="contact-detail">
              <FaEnvelope className="contact-icon" /> <span>Email / ईमेल:</span>{" "}
              customercare@laxmisweets.in
            </Col>
            <Col xs={12} md={6} className="contact-detail">
              <FaMapMarkerAlt className="contact-icon" />{" "}
              <span>Address / पता:</span>
              
              <p>
                569ka/349 Sneh Nagar Alambagh Lucknow 226005.Behind Shiv Shanti
                Ashram VIP road.{" "}
              </p>
              <p>
                569का/349 स्नेह नगर, आलमबाग, लखनऊ 226005। शिव शांति आश्रम के
                पीछे, वीआईपी रोड।
              </p>
              <b>
                <a
                  style={{ color: "inherit", textDecoration: "none" }}
                  href="https://www.google.com/maps?q=26.801407,80.900482" // Replace with your coordinates
                  target="_blank"
                  rel="noopener noreferrer"
                  className="map-link"
                >
                  <FaMapMarkerAlt className="contact-icon" /> Click to Find us
                  on Map
                </a>
              </b>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default About;
