import Carousel from 'react-bootstrap/Carousel';
import './SliderWindow.css'; // Import the CSS file

function SliderWindow() {
  return (
    <Carousel data-bs-theme="dark" slide controls indicators interval={1000} >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/gulabjamun.png"
          alt="First slide"
        />
        <Carousel.Caption>
          <h5>Gulab Jamun (गुलाब जामुन)</h5>
          <p>Gulab Jamun is a popular Indian dessert made from milk solids, sugar, and ghee. The dough balls are deep-fried and then soaked in a fragrant sugar syrup. They are known for their rich, sweet flavor and soft texture..</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/barfi.png"
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Barfi (बर्फी)</h5>
          <p>Barfi is a popular Indian sweet made from condensed milk and various flavorings such as cardamom, nuts, and fruit. It is known for its smooth, creamy texture and can be garnished with edible silver leaf.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default SliderWindow;
