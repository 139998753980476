import React from "react";
import { Badge, Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import './Navbar.css'; // Import your custom CSS
import { FaWhatsapp } from "react-icons/fa";

function NavBar() {
  return (
    <>
      {["lg"].map((expand) => (
        <Navbar key={expand} expand={expand} style={{ height: 200 }}>
          <Container fluid>
            <Navbar.Brand href="#">
              <Image src="/images/logo.png" className="navbar-logo" roundedCircle />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className='overcanvas'
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <Image src="/images/logo.png" className="navbar-logo" roundedCircle />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-left flex-grow-1 pe-3 h5 navbar-nav" style={{gap:"5%"}}>
                  <Nav.Link as={Link} to="/" style={{ fontWeight: 800 }}>Home</Nav.Link>
                  <Nav.Link as={Link} to="/products" style={{ fontWeight: 800 }}>Products</Nav.Link>
                  {/* <Nav.Link as={Link} to="/chhena" style={{ fontWeight: 800 }}>Chhena (छेना)</Nav.Link>
                  <Nav.Link as={Link} to="/khoye-ki-mithai" style={{ fontWeight: 800 }}>Khoye ki Mitahi (खोये की मिठाई)</Nav.Link> */}
                  <Nav.Link as={Link} to="/about" style={{ fontWeight: 800 }}>About us</Nav.Link>
                 
                </Nav>
                <Nav className="justify-content-end flex-grow-1 pe-3 h5 navbar-nav" style={{gap:"5%"}}>
               
               
              <a
                style={{ color: "inherit", textDecoration: "none" }}
                href="//api.whatsapp.com/send?phone=918299721854&text=Hi I want to place an order."
              >
                 <FaWhatsapp color="green" className="contact-icon" />
              <span>WhatsApp or Call us / व्हाट्सएप:</span>
                +91-8299721854 / +91-9305110788
              </a>
                
                  </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavBar;
