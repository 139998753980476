import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import "./Product.css"; // Import your custom CSS

const productImage = [
    {
      image: "rasgulla.png",
      title: "Rasgulla (रसगुल्ला)",
      type: "Bengali Sweets",
      engDesc:
        "Rasgulla is a popular Indian sweet that originates from the state of West Bengal. It is made from chhena (a type of fresh cheese) and cooked in a light sugar syrup. Rasgulla is known for its soft, spongy texture and sweet flavor.",
      hindiDesc:
        "रसगुल्ला एक प्रसिद्ध भारतीय मिठाई है जो पश्चिम बंगाल राज्य से उत्पन्न हुई है। इसे छेना (ताजे पनीर का एक प्रकार) से बनाया जाता है और हल्की चीनी की चाशनी में पकाया जाता है। रसगुल्ला अपनी मुलायम, स्पॉन्जी बनावट और मीठे स्वाद के लिए जाना जाता है।",
    },
    {
      image: "gulabjamun.png",
      title: "Gulab Jamun (गुलाब जामुन)",
      type: "Fried Sweets",
      engDesc:
        "Gulab Jamun is a popular Indian dessert made from milk solids, sugar, and ghee. The dough balls are deep-fried and then soaked in a fragrant sugar syrup. They are known for their rich, sweet flavor and soft texture.",
      hindiDesc:
        "गुलाब जामुन एक लोकप्रिय भारतीय मिठाई है जो दूध के ठोस पदार्थ, चीनी, और घी से बनाई जाती है। आटे की गेंदों को गहरे तले जाते हैं और फिर सुगंधित चीनी की चाशनी में डूबा दिया जाता है। यह मिठाई अपनी समृद्ध, मीठी स्वाद और मुलायम बनावट के लिए जानी जाती है।",
    },
    {
      image: "jalebi.png",
      title: "Jalebi (जलेबी)",
      type: "Fried Sweets",
      engDesc:
        "Jalebi is a traditional Indian sweet made from fermented dough that is deep-fried in a circular shape and then soaked in sugar syrup. It is known for its crispy texture and tangy sweetness.",
      hindiDesc:
        "जलेबी एक पारंपरिक भारतीय मिठाई है जो खमीर लगे आटे से बनाई जाती है जिसे गोल आकार में गहरे तले जाते हैं और फिर चीनी की चाशनी में डूबा दिया जाता है। यह मिठाई अपनी कुरकुरी बनावट और तीखी मिठास के लिए जानी जाती है।",
    },
    {
      image: "barfi.png",
      title: "Barfi (बर्फी)",
      type: "Milk-Based Sweets",
      engDesc:
        "Barfi is a popular Indian sweet made from condensed milk and various flavorings such as cardamom, nuts, and fruit. It is known for its smooth, creamy texture and can be garnished with edible silver leaf.",
      hindiDesc:
        "बर्फी एक लोकप्रिय भारतीय मिठाई है जो गाढ़ा दूध और विभिन्न फ्लेवरिंग जैसे इलायची, मेवे, और फल से बनाई जाती है। यह मिठाई अपनी चिकनी, क्रीमी बनावट के लिए जानी जाती है और इसे खाने योग्य चांदी की पत्तियों से सजाया जा सकता है।",
    },
  
    {
      image: "motichoorLadoo.png",
      title: "Motichoor Ladoo (मोटीचूर लड्डू)",
      type: "Ladoo",
      engDesc:
        "Motichoor Ladoo is a traditional Indian sweet made from fine gram flour beads fried in ghee and then mixed with sugar syrup. It is known for its sweet, melt-in-your-mouth texture and is often enjoyed during festivals and special occasions.",
      hindiDesc:
        "मोटीचूर लड्डू एक पारंपरिक भारतीय मिठाई है जो बारीक बेसन की मोतियों से बनाई जाती है जो घी में तली जाती हैं और फिर चीनी की चाशनी के साथ मिलाई जाती हैं। यह मिठाई अपनी मीठी, मुंह में घुल जाने वाली बनावट के लिए जानी जाती है और आमतौर पर त्योहारों और विशेष अवसरों पर आनंदित की जाती है।",
    },
    {
      image: "peda.png",
      title: "Peda (पेड़ा)",
      type: "Milk-Based Sweets",
      engDesc:
        "Peda is a popular Indian sweet made from condensed milk and flavored with cardamom or saffron. It is often garnished with nuts and has a rich, creamy texture. Peda is commonly enjoyed as a festive treat.",
      hindiDesc:
        "पेड़ा एक लोकप्रिय भारतीय मिठाई है जो गाढ़ा दूध और इलायची या केसर के स्वाद से बनाई जाती है। इसे अक्सर मेवों से सजाया जाता है और इसकी समृद्ध, क्रीमी बनावट होती है। पेड़ा आमतौर पर त्योहारों के मौके पर आनंदित किया जाता है।",
    },
    {
      image: "rasmalai.png",
      title: "Rasmalai (रसमलाई)",
      type: "Bengali Sweets",
      engDesc:
        "Rasmalai is a creamy, milk-based sweet from the state of West Bengal. It consists of chhena dumplings soaked in sweetened, flavored milk, often garnished with saffron and cardamom.",
      hindiDesc:
        "रसमलाई एक क्रीमी, दूध आधारित मिठाई है जो पश्चिम बंगाल राज्य से है। इसमें छेना के गोले होते हैं जो मीठे, सुगंधित दूध में डूबे होते हैं, अक्सर केसर और इलायची से सजाए जाते हैं।",
    },
  
   
    {
      image: "kalakand.png",
      title: "Kalakand (कलाकंद)",
      type: "Milk-Based Sweets",
      engDesc:
        "Kalakand is a milk-based sweet with a grainy texture, made from condensed milk and paneer. It is flavored with cardamom and often garnished with nuts.",
      hindiDesc:
        "कलाकंद एक दूध आधारित मिठाई है जिसकी दानेदार बनावट होती है, जो गाढ़ा दूध और पनीर से बनाई जाती है। इसे इलायची के साथ स्वादित किया जाता है और अक्सर मेवों से सजाया जाता है।",
    },
    
  ];
  
const Products = () => {
  const [isHindi, setIsHindi] = useState(true);

  return (
    <>
      <Row className="full-width">
        <Col>
          <div className="checkbox-container">
            <Form.Check
              type="switch"
              id="custom-switch"
              onClick={() => setIsHindi(!isHindi)}
              label={!isHindi ? 'Read in Hindi' : 'Read in English'}
            />
          </div>
        </Col>
      </Row>

      <Row xs={1} sm={2} md={3} lg={3} xl={4} className="g-4 m-2">
        {productImage.map((product, idx) => (
          <Col key={idx}>
            <Card style={{ height: "500px" }} className="card-custom">
              <Card.Img variant="top" src={`images/${product.image}`} />
              <Card.Body>
                <Card.Title style={{ color: "#FFD700", fontWeight: 800 }}>
                  {product.title}
                </Card.Title>

                {isHindi && (
                  <Card.Text style={{ fontWeight: 600 }}>
                    {product.hindiDesc}
                  </Card.Text>
                )}
                {!isHindi && (
                  <Card.Text style={{ color: "black", fontWeight: 600 }}>
                    {product.engDesc}
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Products;
