import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SliderWindow from "./SliderWindow";
import Marquee from "react-fast-marquee";
import "./home.css";
import BestSeller from "../bestseller";
import { FaMapMarkerAlt,FaEnvelope ,FaPhone,FaWhatsapp} from "react-icons/fa";

const Home = () => {

  const handlePhoneClick = () => {
    window.location.href = "tel:+918299721854"; // Replace with your phone number
  };
  const handleEmailClick = () => {
    window.location.href = "mailto:customercare@laxmisweets.in?subject=Inquiry&body=Hello, I would like to inquire about..."; 
  };
  return (
    <Container fluid className="home-container">
      <Marquee className="announcement-marquee" gradient={true}>
        <span className="announcement-text">
          We will be available on Zomato and Swiggy very soon!
        </span>
      </Marquee>
      <Row className="slider-row">
        <Col sm={12} lg={12}>
          <SliderWindow />
        </Col>
      </Row>

      <Row className="bestseller-row">
        <Col sm={12} lg={12}>
          <BestSeller />
        </Col>
      </Row>
     <Row className="about-row contact-section">
        <Col xs={12} className="about-col">
          <h2 className="about-title">Contact Us / संपर्क करें</h2>
          <Row onClick={handlePhoneClick} className="contact-details">
            <Col xs={12} md={6} className="contact-detail">
              <FaPhone className="contact-icon" /> <span>Phone / फोन:</span>{" "}
              +91-8299721854 / +91-9305110788
            </Col>
            <Col xs={12} md={6} className="contact-detail">
              <FaWhatsapp className="contact-icon" />{" "}
              <span>WhatsApp / व्हाट्सएप:</span>{" "}
              <a
                style={{ color: "inherit", textDecoration: "none" }}
                href="//api.whatsapp.com/send?phone=918299721854&text=Hi I want to place an order."
              >
                +91-8299721854 / +91-9305110788
              </a>
            </Col>
          </Row>
          <Row className="contact-details">
            <Col onClick={handleEmailClick} xs={12} md={6} className="contact-detail">
              <FaEnvelope className="contact-icon" /> <span>Email / ईमेल:</span>{" "}
              customercare@laxmisweets.in
            </Col>
            <Col xs={12} md={6} className="contact-detail">
              <FaMapMarkerAlt className="contact-icon" />{" "}
              <span>Address / पता:</span>
              
              <p>
                569ka/349 Sneh Nagar Alambagh Lucknow 226005.Behind Shiv Shanti
                Ashram VIP road.{" "}
              </p>
              <p>
                569का/349 स्नेह नगर, आलमबाग, लखनऊ 226005। शिव शांति आश्रम के
                पीछे, वीआईपी रोड।
              </p>
              <b>
                <a
                  style={{ color: "inherit", textDecoration: "none" }}
                  href="https://www.google.com/maps?q=26.801407,80.900482" // Replace with your coordinates
                  target="_blank"
                  rel="noopener noreferrer"
                  className="map-link"
                >
                  <FaMapMarkerAlt className="contact-icon" /> Click to Find us
                  on Map
                </a>
              </b>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="footer-row justify-content-center">
        <Col>
          <footer className="footer">
            <p className="footer-text">
              Please note that all images are for reference purposes only. The actual product may differ in appearance; however, we ensure that the quality and experience remain uncompromised.
            </p>
            <p className="footer-text">&copy; 2024 Laxmi Sweets. All rights reserved.</p>
          </footer>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
